body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    background: white !important;
    text-align: center;
    color: #3c3c3c;
    font-family: "Roboto", sans-serif;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  a {
    color: #3c3c3c;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  a:hover {
    color: var(--primary-color);
    text-decoration: none;
  }


  button,.fuk-button {
      border-radius: var(--border-radius) !important;
  }


  .react-confirm-alert-body {
    padding: 0 !important;

  }
  .react-confirm-alert-body > .fuk-button {
    display: none;
  }
  

  @media only screen and (max-width: 1000px) {
    html {
      font-size: 13px !important;
    }
  }