:root {
  --primary-color: #d32c30;
  --secondary-color: #343434;
  --background-color: #3c3c3c;

  --border-radius: 5px;

  --green: #d32c30;
}

::-webkit-input-placeholder { /* Edge */
  color: #ccc;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

#root {
  margin-bottom: -20px;
  content-visibility: auto;
}


@page {
  margin: 1cm;
}


@media print {

  html {
    font-size: 12px !important;
    zoom: 0.9;
  }
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
  }

  .ch2 {
    display: none;
  }

  header.header {
    box-shadow: none;
    background: transparent !important;
    margin-bottom: -20px;
  }
  header.header ul {
    display: none;
  }
  .report-info {
    margin-top: -80px;
    margin-bottom: 50px;
  }
  .section {
    filter: none !important;
    width: 800px;
    margin: auto;
  }


  .bidding-audit .section {
    background: none !important;
  }
  .section > div {
    background: transparent !important;
    margin-top: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    clip-path: none !important;
  }
  .fuk-button {
    display: none !important;
  }

  .fuk-grid.flags {
    display: block;
  }
  .fuk-grid.flags > div {
      display: inline-block;
      margin: 5px 40px;
      width: 200px;
      height: 200px;
  }

  .section.pies {
    text-align: center;
    padding-bottom: 20px !important;
  }

  .section.pies .fuk-header {
    text-align: center;
    margin: auto;
    padding-top: 20px !important;
    margin-bottom: 0 !important;
  }

  .section.pies .fuk-grid {
    grid-gap: 5px;
    display: block;
    margin: auto !important;
    text-align: center;
  }

  .section.pies .pie-graph {
    padding-bottom: 50px;
    width: 500px !important;
    margin: auto !important;
  }

  .wave-divider  {
    display: none;
  }


  .competitors .fuk-grid {
    display: block;
  }



  .audit-footer {
    border-top: 1px solid #ccc;
    margin-top: 0 !important;
  }
  .audit-footer .fuk-grid {
    display: block;
  }
  .audit-footer img {
    display: none;
  }
  .audit-footer > div {
    clip-path: none !important;
  }
  a.activate-button {
    display: none !important;
  }

  .empty {
    margin: 5px 20px !important;
  }
  .footer {
    border-top: 1px solid #ccc;
    background: transparent !important;
    color: #333 !important;
    padding-top: 20px !important;
    margin-top: 0 !important;
  }

  .footer img {
    display: none;
  }

  .npbreak {
    page-break-inside: avoid;
  }

  .pbreak {
    page-break-before: always;
  }

  .positions.section {
    margin: auto;
    margin-top: 0px !important;
    position: relative;
    margin-bottom: -80px !important;
  }


  .positions .chartjs-render-monitor {
    width: 800px !important;
    height: 200px !important;
  }

  .bidding-audit .competitors {
    padding-top: 100px;
  }


  .categories {
    margin-top: -80px !important;
  }

}


body a, body a:visited, body a:active {
  color: #555;
  text-decoration: underline;
}
body a:hover {
  text-decoration: none;
}


th {
  background: #f6f6f6 !important;
}